.root-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  margin-bottom: 80px;
}

.item-container {
  margin-top: 20px;
  min-width: 70vw;

  gap: 20px;

  display: grid;
  grid-template-columns: 1fr 1fr;
}

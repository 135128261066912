.keplr-button {
    width: 100%;
    height: 2.75rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 0.375rem;
    overflow: hidden;

    font-weight: 500;
    font-size: 1rem;

    letter-spacing: 0.2px;

    white-space: nowrap;

    border-width: 0;
    border-style: none;
    border-color: transparent;
    border-image: none;
    padding: 0 0.75rem;

    position: relative;

    color: #FEFEFE;
    background-color: #2C4BE2;
}

.keplr-button:hover {
    opacity: 0.9;
}
